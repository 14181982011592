<template>
    <div>
        <div
            v-if="item.running.value"
            class="dealer-name">
            <styled-tooltip
                nudge-top="5"
                position="top">
                <template #content>
                    <div v-if="item.running.status === 'running'">
                        Pinterest stats are currently being populated for this date range.
                        Please check back soon to see your data!
                    </div>
                    <div v-else>
                        Pinterest stats processing failed. Sometimes this can be resolved by clicking retry.
                        If not, click the info icon for additional steps to resolve the issue.
                    </div>
                </template>
                <div class="dealer-holder">
                    <icon
                        v-if="item.running.status === 'running'"
                        class="mr-2"
                        size="15"
                        :color="colors.yellow"
                        name="indicator-warning" />
                    <icon
                        v-else
                        class="mr-2"
                        size="15"
                        :color="colors.red"
                        name="indicator-error" />
                    <span>{{ item.dealer_name }}</span>
                </div>
                <div
                    class="dealer-processing"
                    :class="{'failed': item.running.status === 'failed'}">
                    <v-progress-circular
                        v-if="item.running.status === 'running'"
                        class="mr-2"
                        size="15"
                        width="2"
                        color="primary"
                        indeterminate />
                    <span v-if="item.running.status === 'running'">Stats processing...</span>
                    <span v-else>Stats failed</span>
                    <div
                        v-if="item.running.status === 'failed'"
                        class="info-failed">
                        <a
                            class="ml-2"
                            @click.prevent="$emit('callback')">retry</a>
                        <span @click="checkConnection(item.dealer_id)">
                            <icon
                                class="ml-2 info-icon"
                                size="15"
                                name="indicator-info" />
                        </span>
                    </div>
                </div>
            </styled-tooltip>
        </div>
        <div v-else>
            {{ item.dealer_name }}
        </div>
    </div>
</template>

<script>
import Icon from '@/components/globals/Icon';
import StyledTooltip from '@/components/globals/StyledTooltip';
import colors from '@/helpers/colors';
import { SET_CHANNEL_CONNECTION_DEBUG_PANEL } from '@/store/mutation-types';

export default {
    components: {
        Icon,
        StyledTooltip
    },
    props: {
        item: {
            type: Object,
            required: true
        }
    },
    data() {
        return {
            colors
        };
    },
    methods: {
        checkConnection(dealerId) {
            this.$store.commit(SET_CHANNEL_CONNECTION_DEBUG_PANEL, { status: true, channel: 'pinterest', dealer_id: dealerId });
        }
    }
};
</script>

<style lang="scss" scoped>
.dealer-name {
    position: relative;
}
.dealer-holder {
    display: flex;
    align-items: center;
}
.dealer-processing {
    display: flex;
    align-items: center;
    position: absolute;
    left: calc(100% + 15px);
    top: 50%;
    margin-top: -13px;
    white-space: nowrap;
    font-size: 10px;
    color: $gray;
    background: $gray-lightshade;
    padding: 5px 10px;
    border-radius: 5px;
    z-index: 9;
}
.dealer-processing.failed {
    background: $light-error;
}
.info-icon {
    cursor: pointer;
}
.info-failed {
    display: flex;
    align-items: center;
}
</style>